const styles = {
  container: 'flex items-center justify-center font-sans',
  content: 'flex flex-col items-center gap-y-2 my-24',

  head: 'flex flex-col items-center',
  foot: '',

  title:
    'font-nbi-pro text-8xl sm:text-[12rem] xl:text-[20rem] opacity-5 font-thin text-center pointer-events-none text-black dark:text-white',
  description:
    'mt-24 text-xl font-semibold tracking-display text-black dark:text-white',

  button: {
    container:
      'flex flex-row items-center gap-x-4 text-black dark:text-white -mt-2',
    label: 'text-center hover:underline ',
  },
};

export default styles;
