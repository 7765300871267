const base = {
  container: '',
  content: '',
};

const breakStyles = {
  container: 'container mx-auto sm:px-6 lg:px-8',
};

const center = {
  container: 'max-w-screen mx-5 sm:mx-12 flex flex-col grow items-center',
  content: 'max-w-7xl w-full flex flex-col grow',
};

const fill = {
  container: 'max-w-7xl mx-auto sm:px-6 lg:px-8',
};

const styles = {
  base,

  break: breakStyles,
  center,
  fill,
};

export default styles;
