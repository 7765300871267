import React from 'react';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { TNextClientComponent } from 'types/next';
import { useTranslation } from 'hooks/useTranslation';
import Icon from 'components/custom/Icon';
import Container from 'components/tailwind-catalyst/Container';
import { styles } from '.';
type TProps = {
  props?: any;
};
const ErrorLayout: TNextClientComponent<TProps> = () => {
  const params = useParams();
  const {
    t
  } = useTranslation(params?.lang as string);
  return <div className={styles.container} data-sentry-component="ErrorLayout" data-sentry-source-file="ErrorLayout.tsx">
      <Container type="center" data-sentry-element="Container" data-sentry-source-file="ErrorLayout.tsx">
        <div className={styles.content}>
          <div className={styles.head}>
            <h1 className={styles.title}>Oops.</h1>
            <p className={styles.description}>
              {t('layout.error.description')}
            </p>
          </div>
          <Link href={`/${params?.lang}`} data-sentry-element="Link" data-sentry-source-file="ErrorLayout.tsx">
            <div className={styles.button.container}>
              <Icon type="light" accessor="arrow-right-long" data-sentry-element="Icon" data-sentry-source-file="ErrorLayout.tsx" />
              <p className={styles.button.label}>{t('layout.error.button')}</p>
            </div>
          </Link>
        </div>
      </Container>
    </div>;
};
export default ErrorLayout;